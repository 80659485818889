<template>
  <section-wrapper class="section__container">
    <div class="col">
      <figure>
        <nuxt-picture class="figure__picture" src="/404-grandpa.svg" />
      </figure>
    </div>

    <div class="col col__text">
      <h1 class="section__title">
        <span>Error 404</span>
        <span>{{ $t("pageNotFound.title") }}</span>
      </h1>

      <p class="section__subtitle gradient-text">
        {{ $t("pageNotFound.subtitle") }}
      </p>

      <p class="section__description">
        {{ $t("pageNotFound.description") }}
      </p>

      <app-button variant="secondary" @click="handleError">
        {{ $t("pageNotFound.button") }}
      </app-button>
    </div>
  </section-wrapper>
</template>

<script lang="ts" setup>
import AppButton from "~/modules/shared/components/AppButton.vue";
import SectionWrapper from "~/modules/shared/components/SectionWrapper.vue";

const localePath = useLocalePath();
const handleError = () => clearError({ redirect: localePath("/") });
</script>

<style lang="scss" scoped>
.section__container {
  :deep(.section-inner__container) {
    @apply md:flex-row;
  }

  .col {
    @apply md:w-1/2;

    &__text {
      @apply md:w-2/3 lg:w-1/2 flex flex-col justify-center items-start pl-20;
    }
  }

  .figure__picture {
    @apply flex justify-center w-72 md:w-auto mx-auto mb-20 md:mb-0;
  }

  .section__title {
    @apply flex flex-col mb-12 text-title;
  }

  .section__subtitle {
    @apply mb-16;
  }

  .section__description {
    @apply w-4/5 mb-16 text-body-xs text-primary-400;
  }
}
</style>
