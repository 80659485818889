<template>
  <section class="section__container">
    <div class="section-inner__container">
      <header
        v-if="$slots['title'] || $slots['header-additional']"
        class="section__header"
      >
        <h2
          v-if="$slots['title']"
          :class="{ 'section__title--left': !!$slots['header-additional'] }"
          class="header__title"
        >
          <slot name="title" />
        </h2>

        <slot name="header-additional" />
      </header>

      <slot />
    </div>

    <slot name="additional-post" />
  </section>
</template>

<style lang="scss" scoped>
.section__container {
  @apply relative w-full z-10;

  .section-inner__container {
    @apply max-w-custom flex flex-col mx-auto py-12 px-8 md:px-16 lg:px-36;
  }

  .section__header {
    @apply relative w-full flex flex-col items-center;
  }

  .header__title {
    @apply mb-10 md:text-center text-title-md;

    &--left {
      @apply text-left;
    }
  }
}
</style>
