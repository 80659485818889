import type { NotifiableError } from "@bugsnag/core";
import type { ClientErrorSlug } from "~/modules/shared/ts/ClientErrorSlug";

export const useErrorTracking = () => {
  const prepareErrorMessage = (error: unknown) => {
    return JSON.stringify(error);
  };

  const trackError = (slug: ClientErrorSlug, error: unknown) => {
    const errorObject: NotifiableError = {
      name: slug,
      message: prepareErrorMessage(error),
    };

    if (import.meta.dev) {
      return console.info("Tracked error:", errorObject);
    }

    useBugsnag().notify(errorObject);
  };

  return { trackError };
};
