import dayjs from "dayjs";
import { defineStore } from "pinia";
import type { UserSubscription } from "~/modules/auth/ts/UserSubscription.type";
import { TIER_ENTERPRISE } from "~/modules/subscription/constants";

export const useUserSubscriptionStore = defineStore("userSubscription", {
  state: () =>
    ({
      userSubscription: null,
    }) as {
      userSubscription: UserSubscription | null | undefined;
    },

  getters: {
    isUserSubscriptionActive: (state) => {
      if (!state.userSubscription?.attributes.valid_to) return false;

      return dayjs().isBefore(
        dayjs(state.userSubscription?.attributes.valid_to),
      );
    },

    isEnterpriseSubscriptionTier: (state) => {
      return (
        state.userSubscription?.attributes.subscription_tier?.data?.attributes
          .type === TIER_ENTERPRISE
      );
    },
  },
});
