import { defineStore } from "pinia";

export const useIsMobileStore = defineStore("isMobile", {
  state: () =>
    ({
      isMobile: false,
    }) as {
      isMobile: boolean;
    },

  actions: {
    checkIfMobile() {
      this.isMobile = document.body.offsetWidth < 768;
    },
  },
});
