import { useAsyncData, useI18n, useStrapi4, useStrapiUser } from "#imports";
import type { StrapiLocale } from "@nuxtjs/strapi";
import { useUserSubscriptionStore } from "~/modules/auth/stores/userSubscription";
import type { User } from "~/modules/auth/ts/User.type";
import type { UserSubscriptionDto } from "~/modules/auth/ts/UserSubscription.type";
import { useErrorTracking } from "~/modules/error/composables/useErrorTracking";
import { CLIENT_ERROR_SLUGS } from "~/modules/shared/constants/ClientErrorSlugs";
import { useGlobalStore } from "~/modules/shared/stores/global";
import type { GlobalSettingsDto } from "~/modules/shared/ts/dto/GlobalSettingsDto";

export const useAppSetup = async () => {
  const globalStore = useGlobalStore();
  const userSubscriptionStore = useUserSubscriptionStore();
  const { find } = useStrapi4();
  const { locale } = useI18n();
  const { trackError } = useErrorTracking();

  const user = useStrapiUser<User>();
  const { data, error } = await useAsyncData(
    `${locale.value}-global-settings`,
    () =>
      find<GlobalSettingsDto>("setting", {
        populate: "deep,6",
        locale: locale.value as StrapiLocale,
      }),
  );

  if (data.value) {
    globalStore.$patch((state) => {
      state.contactPage = data.value?.data.attributes.contact_page?.data;
      state.eventsListPage = data.value?.data.attributes.events_list_page?.data;
      state.footer = data.value?.data.attributes.footer_data;
      state.footerMenuPrimary =
        data.value?.data.attributes.footer_primary_menu?.data;
      state.footerMenuSecondary =
        data.value?.data.attributes.footer_secondary_menu?.data;
      state.headerMenu = data.value?.data.attributes.header_menu?.data;
      state.homepage = data.value?.data.attributes.homepage?.data;
      state.postsListPage = data.value?.data.attributes.posts_list_page?.data;
    });
  }

  if (user.value) {
    const { data: userSubscriptionData } = await useAsyncData(
      "user-subscription",
      () => find<UserSubscriptionDto>("user-subscription"),
    );
    userSubscriptionStore.userSubscription = userSubscriptionData.value?.data;
  }

  if (error.value) {
    trackError(CLIENT_ERROR_SLUGS.GLOBAL_INIT, error);
  }
};
