import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91page_93XjZzxmj3IbMeta } from "/app/pages/blog/page/[page].vue?macro=true";
import { default as helpfVU4hPj97oMeta } from "/app/pages/dashboard/help.vue?macro=true";
import { default as indexMz7ffZfliDMeta } from "/app/pages/dashboard/settings/index.vue?macro=true";
import { default as personalmS5ft3BTrZMeta } from "/app/pages/dashboard/settings/personal.vue?macro=true";
import { default as securityi35Qy6Kdq1Meta } from "/app/pages/dashboard/settings/security.vue?macro=true";
import { default as settingsrNnc9GYBsHMeta } from "/app/pages/dashboard/settings.vue?macro=true";
import { default as subscriptionN5MjQqyfYcMeta } from "/app/pages/dashboard/subscription.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91slug_935xGnk5JrpsMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as _91page_93XuVSY0V4gJMeta } from "/app/pages/events/page/[page].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as sign_45inMH4whhaZtqMeta } from "/app/pages/sign-in.vue?macro=true";
import { default as indexNjcl7E3mjtMeta } from "/app/pages/sign-up/index.vue?macro=true";
import { default as signup_46consts6mWCb6PTG5Meta } from "/app/pages/sign-up/signup.consts.ts?macro=true";
import { default as signup_46types0oXaeYEzDQMeta } from "/app/pages/sign-up/signup.types.ts?macro=true";
import { default as success3BD8z3TQtkMeta } from "/app/pages/sign-up/success.vue?macro=true";
import { default as waitlistxPEJqwKUh4Meta } from "/app/pages/waitlist.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug()",
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pt",
    path: "/:slug()",
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___pt",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___pt",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-page-page___en",
    path: "/blog/page/:page()",
    component: () => import("/app/pages/blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: "blog-page-page___pt",
    path: "/blog/page/:page()",
    component: () => import("/app/pages/blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-help___en",
    path: "help",
    component: () => import("/app/pages/dashboard/help.vue").then(m => m.default || m)
  },
  {
    name: settingsrNnc9GYBsHMeta?.name,
    path: "settings",
    component: () => import("/app/pages/dashboard/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-settings___en",
    path: "",
    meta: indexMz7ffZfliDMeta || {},
    component: () => import("/app/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-personal___en",
    path: "personal",
    component: () => import("/app/pages/dashboard/settings/personal.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-security___en",
    path: "security",
    component: () => import("/app/pages/dashboard/settings/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-subscription___en",
    path: "subscription",
    component: () => import("/app/pages/dashboard/subscription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard___pt",
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-help___pt",
    path: "help",
    component: () => import("/app/pages/dashboard/help.vue").then(m => m.default || m)
  },
  {
    name: settingsrNnc9GYBsHMeta?.name,
    path: "settings",
    component: () => import("/app/pages/dashboard/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-settings___pt",
    path: "",
    meta: indexMz7ffZfliDMeta || {},
    component: () => import("/app/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-personal___pt",
    path: "personal",
    component: () => import("/app/pages/dashboard/settings/personal.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-security___pt",
    path: "security",
    component: () => import("/app/pages/dashboard/settings/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-subscription___pt",
    path: "subscription",
    component: () => import("/app/pages/dashboard/subscription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events-slug___en",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___pt",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___pt",
    path: "/events",
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events-page-page___en",
    path: "/events/page/:page()",
    component: () => import("/app/pages/events/page/[page].vue").then(m => m.default || m)
  },
  {
    name: "events-page-page___pt",
    path: "/events/page/:page()",
    component: () => import("/app/pages/events/page/[page].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___en",
    path: "/sign-in",
    meta: sign_45inMH4whhaZtqMeta || {},
    component: () => import("/app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-in___pt",
    path: "/sign-in",
    meta: sign_45inMH4whhaZtqMeta || {},
    component: () => import("/app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___en",
    path: "/sign-up",
    component: () => import("/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-signup.consts___en",
    path: "/sign-up/signup.consts",
    component: () => import("/app/pages/sign-up/signup.consts.ts").then(m => m.default || m)
  },
  {
    name: "sign-up-signup.consts___pt",
    path: "/sign-up/signup.consts",
    component: () => import("/app/pages/sign-up/signup.consts.ts").then(m => m.default || m)
  },
  {
    name: "sign-up-signup.types___en",
    path: "/sign-up/signup.types",
    component: () => import("/app/pages/sign-up/signup.types.ts").then(m => m.default || m)
  },
  {
    name: "sign-up-signup.types___pt",
    path: "/sign-up/signup.types",
    component: () => import("/app/pages/sign-up/signup.types.ts").then(m => m.default || m)
  },
  {
    name: "sign-up-success___en",
    path: "/sign-up/success",
    component: () => import("/app/pages/sign-up/success.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-success___pt",
    path: "/sign-up/success",
    component: () => import("/app/pages/sign-up/success.vue").then(m => m.default || m)
  },
  {
    name: "waitlist___en",
    path: "/waitlist",
    component: () => import("/app/pages/waitlist.vue").then(m => m.default || m)
  },
  {
    name: "waitlist___pt",
    path: "/waitlist",
    component: () => import("/app/pages/waitlist.vue").then(m => m.default || m)
  }
]