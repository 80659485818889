<template>
  <component
    :is="component"
    :to="url || page?.attributes?.slug"
    v-bind="componentProps"
  >
    <transition>
      <span v-if="loading" class="loader" />
    </transition>

    <span class="button__text">
      <slot>
        {{ $t("readMore") }}
      </slot>
    </span>
  </component>
</template>

<script lang="ts" setup>
import { computed, resolveComponent } from "#imports";
import type { Page } from "~/modules/shared/ts/Page";

const props = withDefaults(
  defineProps<{
    buttonTag?: boolean;
    disabled?: boolean;
    loading?: boolean;
    page?: Page | null;
    small?: boolean;
    url?: string;
    variant?: "primary" | "secondary";
  }>(),
  {
    buttonTag: false,
    disabled: false,
    loading: false,
    page: null,
    small: false,
    url: undefined,
    variant: "secondary",
  },
);

const componentProps = computed(() => {
  const classes = ["app-button", `app-button--${props.variant}`];

  if (props.loading) classes.push("app-button--loading");
  if (props.disabled) classes.push("app-button--disabled");
  if (props.small) classes.push("app-button--small");

  return {
    class: classes.join(" "),
    ...(!props.buttonTag
      ? {
          to: props.url || props.page?.attributes?.slug,
        }
      : {}),
  };
});
const component = computed(() =>
  props.buttonTag ? "button" : resolveComponent("NuxtLink"),
);
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
@import "@/assets/styles/_global.scss";

$borderRadius: 3.2rem;

.app-button {
  @apply relative inline-flex items-center justify-center px-14 py-4 cursor-pointer text-body-xs;
  color: white;
  transition: 0.4s;
  border-radius: $borderRadius;

  .button__text {
    @apply relative z-10;
  }

  .loader {
    @apply absolute w-5 h-5 rounded-full z-20;
    animation: loader 1.4s linear infinite;
    transform: translateX(-100%);
  }

  &::after,
  &::before {
    @apply absolute;
    content: "";
    border-radius: $borderRadius;
    transition: 0.4s;
  }

  &::before {
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    left: -3px;
  }

  &::after {
    @apply top-0 left-0 w-full h-full bg-primary-50;
  }

  &:hover {
    &::after {
      @apply opacity-0;
    }
  }

  &--primary {
    &:hover {
      @apply text-primary-50;
    }

    &::before {
      @include gradient;
    }

    &::after {
      @apply bg-primary-50;
    }
  }

  &--secondary {
    &:hover {
      background: transparent;
    }

    &::before {
      @apply bg-primary-100;
    }
  }

  &--loading {
    @apply pointer-events-none;

    .button__text {
      @apply opacity-0;
    }
  }

  &--disabled {
    @apply pointer-events-none text-primary-100;

    &::before {
      background: theme("colors.primary-100");
    }
  }

  &--small {
    @apply py-4 px-7 text-body-xxs;
    line-height: 1;

    .loader {
      @apply w-2 h-2;
      transform: translateX(-250%);
    }
  }
}

@keyframes loader {
  0% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 -2px;
  }
  25% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 2px;
  }
  50% {
    box-shadow:
      14px 0 0 2px,
      38px 0 0 -2px,
      -14px 0 0 -2px;
  }
  75% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 2px,
      -14px 0 0 -2px;
  }
  100% {
    box-shadow:
      14px 0 0 -2px,
      38px 0 0 -2px,
      -14px 0 0 -2px;
  }
}
</style>
