export const CLIENT_ERROR_SLUGS = {
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CREATE_PAYMENT: "CREATE_PAYMENT",
  EVENT_INIT: "EVENT_INIT",
  EVENTS_LIST_INIT: "EVENTS_LIST_INIT",
  GLOBAL_INIT: "GLOBAL_INIT",
  NEWSLETTER_SIGN_UP: "NEWSLETTER_SIGN_UP",
  PAGE_INIT: "PAGE_INIT",
  POST_CATEGORIES_INIT: "POST_CATEGORIES_INIT",
  POST_INIT: "POST_INIT",
  POSTS_LIST_INIT: "POSTS_LIST_INIT",
  REMOVE_AVATAR: "REMOVE_AVATAR",
  RESUME_SUBSCRIPTION: "RESUME_SUBSCRIPTION",
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  SUBSCRIPTION_TIERS_INIT: "SUBSCRIPTION_TIERS_INIT",
  UPDATE_USER: "UPDATE_USER",
  WAITLIST_SIGN_UP: "WAITLIST_SIGN_UP",
} as const;
