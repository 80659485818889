<template>
  <nuxt-layout :key="`${locale}-locale`">
    <nuxt-page />
  </nuxt-layout>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, useI18n, useRuntimeConfig } from "#imports";
import { useAppSetup } from "~/modules/shared/composables/useAppSetup";

import { useIsMobileStore } from "~/modules/shared/stores/isMobile";

const { locale } = useI18n();
const { checkIfMobile } = useIsMobileStore();

const config = useRuntimeConfig();

useAppSetup();

onMounted(() => {
  window.addEventListener("resize", checkIfMobile);
  window.heap.load(config.public.heapId);
  checkIfMobile();
});
onUnmounted(() => window.removeEventListener("resize", checkIfMobile));
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
</style>
