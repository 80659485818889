<template>
  <nuxt-layout>
    <page-not-found v-if="error.statusCode === 404" />
  </nuxt-layout>
</template>

<script lang="ts" setup>
import type { NuxtError } from "#app";
import PageNotFound from "~/modules/error/components/PageNotFound.vue";
import { useAppSetup } from "~/modules/shared/composables/useAppSetup";

defineProps<{
  error: NuxtError;
}>();

useAppSetup();
</script>
